import axios from 'axios'
import {
	requestInterceptor,
	responseInterceptor,
	errorInterceptor
} from './interceptors'

// instance 생성
const createInstance = (url, options) => {
	const instanceAuth = axios.create({ baseURL: url }, options)
	instanceAuth.interceptors.request.use(requestInterceptor, errorInterceptor)
	instanceAuth.interceptors.response.use(responseInterceptor, errorInterceptor)
	return instanceAuth
}

// 인증 인터셉터 추가된 axios instance
const instance = createInstance(process.env.VUE_APP_API_URL)

export default {
	// get(json)
	GET: async (url, params, noLoadingOverlay) => {
		const response = await instance.get(url, {
			params,
			loading: !noLoadingOverlay
		})
		return response.data
	},
	// post(json)
	POST: async (url, params, noLoadingOverlay) => {
		const response = await instance
			.post(url, params, {
				loading: !noLoadingOverlay
			})
			.catch(response => {
				return response
			})
		return response
		/* const response = await instance.post(url, params, {
			loading: !noLoadingOverlay
		})
		return response.data */
	},
	// put(json)
	PUT: async (url, params, noLoadingOverlay) => {
		const response = await instance.put(url, params, {
			loading: !noLoadingOverlay
		})
		return response.data
	},
	// delete(json)
	DELETE: async (url, params, noLoadingOverlay) => {
		const response = await instance.delete(url, {
			params,
			loading: !noLoadingOverlay
		})
		return response.data
	},
	// request(설정값 필요)
	request: async (method, url, headers, params, noLoadingOverlay) => {
		const response = instance.request({
			method,
			url,
			headers,
			params,
			loading: !noLoadingOverlay
		})

		return response
	},
	fileDownloadGET: async (url, params, noLoadingOverlay) => {
		const response = await instance.get(url, {
			params,
			loading: !noLoadingOverlay,
			responseType: 'blob'
		})
		return response.data
	}
}
