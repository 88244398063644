import { ko } from 'vuetify/es5/locale'
import common from './common.msg'
import board from './board'

const messages = {
	$vuetify: ko,
	common,
	board
}

export default messages
