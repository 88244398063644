import Vue from 'vue'
import Vuex from 'vuex'
import common from './common.store'
import codyPool from './codyPool.store'
import contents from './contents.store'
import frontStore from './front.store'
import commission from './commission.store'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		common,
		codyPool,
		contents,
		frontStore,
		commission
	}
})
