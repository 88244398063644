export default {
	rules: {
		required: '필수 입력값입니다',
		loginIdRequired: '아이디를 입력해 주세요.',
		loginPasswdRequired: '비밀번호를 입력해 주세요.',
		maxLength: '최대 {0}자 까지 입력해 주세요',
		minLength: '최소 {0}자 이상 입력해 주세요',
		email: '이메일 형식으로 입력해 주세요',
		phoneNumber: "'-'를 제외한 전화번호 형식으로 입력해 주세요",
		numeric: '숫자만 입력 가능합니다',
		alphaNumeric: '영문, 숫자로 입력해 주세요',
		sameAs: '{0}와 같은 값을 입력해 주세요',
		ipv4Address: 'IP(v4) 형태로 입력해 주세요',
		ipv6Address: 'IP(v6) 형태로 입력해 주세요',
		strongPassword:
			'영문, 숫자 및 특수문자를 포함하여 {0} ~ {1}자 까지 입력해 주세요',
		filetype: '첨부파일 기준을 확인해 주세요.',
		url: '인터넷 주소 형식에 맞지 않습니다.'
	},
	word: {
		account: '계정',
		name: '이름',
		email: '이메일',
		password: '비밀번호',
		passwordConf: '비밀번호 확인',
		submit: '등록',
		alert: '알림',
		confirm: '확인',
		cancel: '취소',
		close: '닫기',
		selectAll: '전체 선택',
		select: '선택',
		change: '변경',
		login: '로그인',
		today: '오늘',
		save: '저장',
		update: '수정',
		list: '목록',
		delete: '삭제',
		noDataText: '검색된 내역이 없습니다.',
		noRegDataText: '등록된 게시글이 없습니다.',
		noCustomerText: '등록된 고객이 없습니다.',
		yes: '예',
		no: '아니요',
		sms: '문자 보내기'
	},
	msg: {
		goHome: '메인화면으로 이동',
		saveConfirm: '저장하시겠습니까?',
		saveComplete: '저장되었습니다.',
		deleteConfirm: '삭제하시겠습니까?',
		deleteComplete: '삭제 되었습니다.',
		updateConfirm: '수정하시겠습니까?',
		updateComplete: '수정되었습니다.',
		smsConfirm: '${s} 명에게 발송 하시겠습니까?'
	},
	error: {
		'404': {
			title: '페이지를 찾을 수 없습니다. 서비스 이용에 불편을 드려 죄송합니다',
			text:
				'요청하신 페이지의 주소가 잘못 입력되었거나, 페이지의 주소 변경 혹은 삭제로 사용하실 수 없습니다.'
		},
		'500': {
			title:
				'오류가 발생하여 페이지를 표시할 수 없습니다. 서비스 이용에 불편을 드려 죄송합니다.',
			text:
				'페이지를 불러오는 중 오류가 발생했습니다. 잠시후 이용 부탁드립니다.'
		},
		'1014': {
			text: '기간 설정 날짜를 확인해 주세요'
		},
		'1015': {
			title: '페이지에 접근할 수 있는 권한이 없습니다',
			text:
				'페이지에 접근할 수 있는 권한이 없습니다. 관리자에게 확인해 주시기 바랍니다.'
		},
		trans: {
			title: '제품추천 서비스 이관 안내',
			text:
				"제품추천서비스가 라이프 스토리로 변경됩니다.<p/><p/>'디지털 세일즈 App' 다운받아 설치 후<br/> '라이프 스토리'를 이용해 주세요. <p/>※ 앱 다운로드는 http://apps.coway.com 에서 가능합니다."
		},
		all: {
			title: '오류',
			text: '오류가 발생했습니다'
		}
	}
}
