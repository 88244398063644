export default [
	{
		path: '/empInsurance',
		name: 'empInsurance',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/empInsurance/Index.vue'),
		children: [
			{
				path: '/empInsurance/list',
				name: 'empInsuranceList',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/empInsurance/EmpInsuranceList.vue')
			}
		]
	}
]
