export default [
	{
		path: '/termination',
		name: 'termination',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/termination/Index.vue'),
		children: [
			{
				path: 'state',
				name: 'state',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/termination/CancelState.vue')
			},
			{
				path: 'request',
				name: 'request',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/termination/CancelRequest.vue')
			},
			{
				path: 'apply',
				name: 'apply',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/termination/CancelApply.vue')
			}
		]
	}
]
