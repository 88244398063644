import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

//admin SCSS
import '@/assets/styles/admin/custom.scss'
import '@/assets/styles/common.scss'
import '@/assets/styles/variable.scss'
//front SCSS
// import '@/assets/styles/front/contents.scss'

//smart SCSS
// import '@/assets/styles/smart/contents.scss'

//commission SCSS
// import '@/assets/styles/commission/contents.sccoway_adminss'

//codyPool SCSS
// import '@/assets/styles/codyPool/contents.scss'

//common SCSS

Vue.use(Vuetify)

export default {
	icons: {
		iconfont: 'mdi' || 'mdiSvg' || 'fa'
	},
	theme: {
		themes: {
			light: {
				primary: '#1B9EDB', // #E53935
				secondary: '#6ec4e8', // #FFCDD2
				accent: colors.blue.base // #3F51B5
			}
		}
	}
}
