const state = {
	siteType: '',
	boardTitle: '',
	boardType: '',
	mainPath: '',
	listPath: '',
	createPath: '',
	detailPath: '',
	detailState: '',
	searchFilters: {
		schStartDate: '',
		schEndDate: '',
		exposureYn: '',
		searchWord: '',
		categoryCd: '',
		subCategoryCd: '',
		status: '',
		currentPage: 1,
		currentPageScroll: 0
	}
}

const getters = {
	siteType: state => {
		return state.siteType
	},
	boardTitle: state => {
		return state.boardTitle
	},
	boardType: state => {
		return state.boardType
	},
	mainPath: state => {
		return state.mainPath
	},
	listPath: state => {
		return state.listPath
	},
	createPath: state => {
		return state.createPath
	},
	detailPath: state => {
		return state.detailPath
	},
	detailState: state => {
		return state.detailState
	},
	searchFilters: state => {
		return state.searchFilters
	}
}

const actions = {}

const mutations = {
	setSiteType: (state, payload) => {
		state.siteType = payload
	},
	setBoardTitle: (state, payload) => {
		state.boardTitle = payload
	},
	setBoardType: (state, payload) => {
		state.boardType = payload
	},
	setMainPath: (state, payload) => {
		state.mainPath = payload
	},
	setListPath: (state, payload) => {
		state.listPath = payload
	},
	setCreatePath: (state, payload) => {
		state.createPath = payload
	},
	setDetailPath: (state, payload) => {
		state.detailPath = payload
	},
	setDetailState: (state, payload) => {
		state.detailState = payload
	},
	setSearchFilters: (state, payload) => {
		state.searchFilters.exposureYn = payload.exposureYn
		state.searchFilters.schStartDate = payload.schStartDate
		state.searchFilters.schEndDate = payload.schEndDate
		state.searchFilters.searchWord = payload.searchWord
		state.searchFilters.categoryCd = payload.categoryCd
		state.searchFilters.subCategoryCd = payload.subCategoryCd
		state.searchFilters.status = payload.status
		state.searchFilters.currentPage = payload.currentPage
		state.searchFilters.currentPageScroll = payload.currentPageScroll

		// eslint-disable-next-line no-console
		console.log('state.searchFilters :::::::::::::: ', state.searchFilters)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
