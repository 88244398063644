import store from '@/store'

/**
 * axios requestInterceptor
 * @param {Object} config: axios configuration
 */
export const requestInterceptor = config => {
	// Authorization header 셋팅
	config.headers.Authorization = null
	if (store.getters['common/accessToken'] === null) {
		config.headers.Authorization = localStorage.getItem('access_token')
	} else {
		config.headers.Authorization = store.getters['common/accessToken']
	}

	// spinner
	if (config.loading) store.commit('common/setLoading', true)

	return config
}

/**
 * axios responseInterceptor
 * @param {Object} config: axios configuration
 */
export const responseInterceptor = config => {
	// spinner
	store.commit('common/setLoading', false)

	return config
}

/**
 * axios errorInterceptor
 * @param {Object} error: axios error object
 */
export const errorInterceptor = error => {
	// spinner
	store.commit('common/setLoading', false)

	// show dialog

	return Promise.reject(error.response)
}
