<template>
	<div class="header_wrap">
		<v-app-bar class="gnb_wrap" fixed app height="56">
			<v-btn icon @click="back">
				<i class="ico_com back">뒤로</i>
			</v-btn>

			<v-spacer></v-spacer>

			<v-toolbar-title>{{ title }}</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn icon @click.stop="drawer = !drawer">
				<i class="ico_com menu">메뉴</i>
			</v-btn>
		</v-app-bar>
		<v-navigation-drawer class="navi_menu" v-model="drawer" absolute temporary>
			<sidebar-menu
				:menu="menuList"
				:disableHover="true"
				:hideToggle="true"
				:collapsed="this.collapsed"
				:widthCollapsed="'0'"
				:theme="'white-theme'"
				@toggle-collapse="onToggleCollapse"
			>
			</sidebar-menu>
		</v-navigation-drawer>
	</div>
</template>

<script>
export default {
	name: 'TopInforBar',
	props: {
		collapsed: Boolean,
		title: String,
		menuList: Array
	},
	updated() {
		this.newcollapsed = this.collapsed
	},
	data() {
		return {
			newcollapsed: false,
			drawer: null,
			menu: [
				{
					href: '/commission/list',
					title: '수수료 조회'
				},
				{
					href: '/commission/detailList',
					title: '수수료 세부내역'
				},
				{
					href: '/commission/monthlyPayment',
					title: '월별 실 지급액'
				}
			]
		}
	},

	methods: {
		back() {
			this.$router.go(-1)
		},
		onToggleCollapse() {
			this.collapsed = !this.collapsed
		},
		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight }
			if (this.windowSize.x < 800) {
				this.collapsed = true
			} else {
				this.collapsed = false
			}
		},
		emitColspased() {
			this.newcollapsed = !this.newcollapsed
			this.$emit('changeColspased', this.newcollapsed)
		}
	}
}
</script>
