export default {
	admin: {
		env: '환경안심 안내',
		envReg: '환경안심 안내 등록',
		envEdit: '환경안심 안내 수정',
		sales: '제품추천 서비스',
		salesReg: '제품추천 서비스 등록',
		salesEdit: '제품추천 서비스 수정',
		cody: '1:1 문의 게시판',
		codyReg: '1:1 문의 답변 등록',
		codyEdit: '1:1 문의 답변 확인/수정',
		notice: '공지사항',
		noticeReg: '공지사항 등록',
		noticeEdit: '공지사항 수정'
	},
	front: {
		env: '환경안심 안내',
		sales: '제품추천 서비스',
		cody: '1:1 문의',
		codyList: '1:1 문의 목록',
		codyReg: '1:1 문의 등록',
		codyDetail: '1:1 문의 내용',
		notice: '공지사항'
	}
}
