/* eslint-disable no-unused-vars */
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import AAlert from '@/components/atoms/AAlert'
import i18n from '@/plugins/i18n'

const PromiseAlert = Vue.extend(AAlert)

function renderAlert(message, title, okText, cancelText, persistent, maxwidth) {
	return new Promise((resolve, reject) => {
		const myAlert = new PromiseAlert({
			methods: {
				closeHandler(fn, arg) {
					return function() {
						fn(arg)
						myAlert.$destroy()
						myAlert.$el.remove()
					}
				}
			},
			render(h) {
				return h(AAlert, {
					props: {
						dialogTitle: title,
						dialogText: message,
						buttonText: okText,
						subButtonText: cancelText,
						persistent,
						maxwidth: maxwidth
					},
					on: {
						confirm: this.closeHandler(() => resolve(true)),
						cancel: this.closeHandler(() => resolve(false))
					}
				})
			},
			vuetify
		}).$mount()
		document.body.appendChild(myAlert.$el)
	})
}

const promiseDialog = {
	install(Vue) {
		// alert
		Vue.prototype.Alert = (message, persistent, title, maxwidth) => {
			return renderAlert(
				message,
				title ? title : i18n.t('common.word.alert'),
				i18n.t('common.word.confirm'),
				undefined,
				persistent !== undefined ? persistent : true,
				maxwidth
			)
		}

		Vue.Alert = (message, persistent, title, maxwidth) => {
			return renderAlert(
				message,
				title ? title : i18n.t('common.word.alert'),
				i18n.t('common.word.confirm'),
				undefined,
				persistent !== undefined ? persistent : true,
				maxwidth
			)
		}

		// confirm
		Vue.prototype.$confirm = (
			message,
			persistent,
			title,
			okText,
			cancelText
		) => {
			return renderAlert(
				message,
				title ? title : i18n.t('common.word.confirm'),
				okText ? okText : i18n.t('common.word.confirm'),
				cancelText ? cancelText : i18n.t('common.word.close'),
				persistent !== undefined ? persistent : true
			)
		}
		Vue.$confirm = (message, persistent, title, okText, cancelText) => {
			return renderAlert(
				message,
				title ? title : i18n.t('common.word.confirm'),
				okText ? okText : i18n.t('common.word.confirm'),
				cancelText ? cancelText : i18n.t('common.word.close'),
				persistent !== undefined ? persistent : true
			)
		}
	}
}

Vue.use(promiseDialog)
