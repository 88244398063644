import api from '@/apis/config'

const { GET } = api

/**
 * 영업 컨텐츠 목록을 조회 한다.
 * @param {*} params
 */
export const getFrontSalesContentList = async params => {
	let url = process.env.VUE_APP_API_URL + '/front/content'
	const response = await GET(
		url,
		{
			...params
		},
		true
	)

	let { pageNo, pageSize } = params ? params : {}
	pageNo = pageNo !== undefined ? pageNo : 1
	pageSize = pageSize !== undefined ? pageSize : 10

	const filteredRows = response.resultObject.articleList
	const paginationResponse = {
		pagination: {
			pageNo,
			pageSize,
			totalCount: response.resultObject.pageInfo.totalCount,
			totalPage: Math.ceil(response.resultObject.pageInfo.totalCount / pageSize)
		},
		rows: filteredRows
	}
	return paginationResponse
}

/**
 * 영업 컨텐츠 상세를 조회한다
 */
export const getFrontContentDetail = async (boardId, params) => {
	let url = process.env.VUE_APP_API_URL + '/front/content/' + boardId
	const response = await GET(url, {
		...params
	})
	return response
}

/**
 * 영업 컨텐츠 고객 목록 조회
 */
export const mgntCustomerPosts = async params => {
	let url = process.env.VUE_APP_API_URL + '/front/customer'
	const response = await GET(url, {
		...params
	})
	return response
}
