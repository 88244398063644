<template>
	<v-dialog fullscreen scrollable hide-overlay persistent v-model="dialog">
		<v-card
			id="scroll-target"
			ref="customerPopup"
			class="fullPopup overflow-y-auto"
		>
			<div class="popup_header">
				<div class="popup_header_title">고객 문자발송</div>
				<v-btn icon @click="closePopup">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
			<div class="px-6" v-scroll:#scroll-target="onScroll">
				<!-- search_box -->
				<div class="search_box mt-6">
					<!-- search_area  -->
					<div class="flex-align">
						<v-text-field
							outlined
							single-line
							color="#222"
							class="search_area"
							hide-details="auto"
							label="이름, 연락처, 제품명"
							v-model="searchWord"
							@keypress.enter="search('click')"
						></v-text-field>
						<v-btn icon class="btn_w btn_search" @click="search('click')">
						</v-btn>
						<v-btn
							icon
							class="btn_w btn_refresh"
							v-show="isSearch"
							@click="search('refresh')"
						>
						</v-btn>
					</div>
				</div>
				<div class="tab_box">
					<v-tabs
						centered
						fixed-tabs
						v-model="tab"
						:class="{ fixed: isTabActive }"
					>
						<v-tab>
							<span class="tab_title" v-if="!isSearch">
								관리 고객({{ pagination.totalCount }})
							</span>
							<span class="tab_title" v-else>
								검색 결과({{ pagination.totalCount }})
							</span>
						</v-tab>
						<v-tab>
							<span class="tab_title"> 발송 선택({{ sendListCount }}) </span>
						</v-tab>
					</v-tabs>
					<v-tabs-items v-model="tab">
						<v-tab-item>
							<div
								class="list_wrap noresult"
								v-if="!loading && isSearch && customer.length < 1"
							>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title
											>검색된 내역이 없습니다.</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
							</div>
							<div class="list_wrap" v-else>
								<v-list-item>
									<v-data-table
										header.data-table-select
										hide-default-header
										hide-default-footer
										show-select
										mobile-breakpoint="0"
										ref="table"
										:no-data-text="loadingText"
										:headers="headers"
										:items="customer"
										:items-per-page="-1"
										v-model="selected"
										item-key="no"
									>
										<template
											v-slot:[`item.data-table-select`]="{
												item,
												isSelected
											}"
										>
											<v-simple-checkbox
												:ripple="false"
												:value="isSelected"
												@input="itemSelect(item, $event)"
											></v-simple-checkbox>
										</template>
										<template
											v-slot:[`item.name`]="{
												item
											}"
											>{{ masking(item.name, 'name') }}</template
										>
										<template
											v-slot:[`item.phone`]="{
												item
											}"
											>{{ masking(item.phone, 'phone') }}</template
										>
										<template
											v-slot:[`item.productNm`]="{
												item
											}"
											>{{ item.productNm }}</template
										>
									</v-data-table>
								</v-list-item>
								<v-progress-circular
									indeterminate
									color="primary"
									v-show="loading"
								></v-progress-circular>
							</div>
						</v-tab-item>
						<v-tab-item>
							<div class="list_wrap" v-if="sendList.length > 0">
								<v-list-item>
									<v-data-table
										header.data-table-select
										hide-default-header
										hide-default-footer
										show-select
										mobile-breakpoint="0"
										:headers="headers"
										:items="sendList"
										:items-per-page="-1"
										v-model="sendSelected"
										item-key="phone"
									>
										<template
											v-slot:[`item.data-table-select`]="{
												item,
												isSelected
											}"
										>
											<v-simple-checkbox
												:ripple="false"
												:value="isSelected"
												@input="itemUnchecked(item, $event)"
											></v-simple-checkbox>
										</template>
										<template
											v-slot:[`item.name`]="{
												item
											}"
											>{{ masking(item.name, 'name') }}</template
										>
										<template
											v-slot:[`item.phone`]="{
												item
											}"
											>{{ masking(item.phone, 'phone') }}</template
										>
										<template
											v-slot:[`item.productNm`]="{
												item
											}"
											>{{ item.productNm }}</template
										>
									</v-data-table>
								</v-list-item>
							</div>
							<div class="list_wrap noresult" v-else>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title
											>선택된 내역이 없습니다.</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
							</div>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</div>
			<div class="btn_wrap_fix">
				<v-btn
					color="primary"
					:disabled="sendList.length === 0"
					large
					min-width="100%"
					@click="smsSend"
					>문자 보내기</v-btn
				>
			</div>
			<v-btn
				fab
				rounded
				fixed
				bottom
				right
				v-show="fab"
				v-scroll="onScroll"
				class="btn_top btn_w btm"
				@click="top"
			>
				<v-icon>mdi-chevron-up</v-icon>
				TOP
			</v-btn>
		</v-card>
	</v-dialog>
</template>

<script>
import moment from 'moment'
import { isEmpty } from '@/utils/stringUtils'
import { eventBus } from '@/eventbus/eventBus'
import { mgntCustomerPosts } from '@/apis/front/frontSales.api'
import { decCodyNo } from '@/apis/common.api'

export default {
	data() {
		return {
			decCodyId: '',
			tab: null,
			fab: false,
			title: '',
			shareUrl: '',
			searchWord: '',
			isSearch: false,
			dialog: false,
			codyId: '',
			deptCd: '',
			customer: [],
			selected: [],
			sendSelected: [],
			sendListCount: 0,
			sendList: [],
			offsetTop: 0,
			isTabActive: false,
			loading: true,
			loadingText: '데이터를 불러오는 중입니다.',
			pagination: {
				totalPage: 0,
				totalCount: 0,
				currentPage: 0,
				pageSize: 20
			},
			headers: [
				{
					text: '전체선택',
					align: 'center',
					sortable: false,
					value: 'name'
				},
				{
					text: '',
					align: 'center',
					sortable: false,
					value: 'phone'
				},
				{
					text: '',
					align: 'center',
					sortable: false,
					value: 'productNm'
				}
			]
		}
	},
	mounted() {
		eventBus.$on('mgntCustomerPopCall', (shareUrl, title, codyId, deptCd) => {
			this.$log('codyId :::::::: ', codyId)
			if (!deptCd) {
				const msg = '지국코드 정보가 없습니다. 관리자에게 문의해 주세요.'
				this.showSnackbar({ msg: msg })
				this.closePopup()
			} else if (!codyId) {
				const msg = '사원 정보가 없습니다. 관리자에게 문의해 주세요.'
				this.showSnackbar({ msg: msg })
				this.closePopup()
			} else {
				this.title = title
				this.shareUrl = shareUrl
				this.codyId = codyId
				this.deptCd = deptCd
				this.getDecCodyNo(this.codyId)
				this.show()
			}
		})
	},
	methods: {
		show() {
			this.$gtag.event('click_share_sms', {
				event_category: this.decCodyId,
				event_label: this.$route.query.boardId + '_' + this.title
			})

			this.dialog = true
			this.search()
			eventBus.$emit('mgntCustomerPop_opened')
		},
		closePopup() {
			this.dialog = false
			this.tab = null
			this.fab = false
			this.searchWord = ''
			this.isSearch = false
			this.customer = []
			this.selected = []
			this.sendSelected = []
			this.sendListCount = 0
			this.sendList = []
			this.offsetTop = 0
			this.isTabActive = false
			this.pagination.totalPage = 0
			this.pagination.totalCount = 0
			this.pagination.currentPage = 0
			eventBus.$emit('mgntCustomerPop_closed')
		},
		top() {
			this.$refs.customerPopup.$el.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			})
		},
		getDecCodyNo(codyId) {
			decCodyNo(codyId).then(response => {
				if (response.code === 200) {
					this.decCodyId = response.resultObject
				}
			})
		},
		itemSelect(item, event) {
			if (event) {
				if (this.sendList.length >= 10) {
					const msg =
						'선택 가능 건수를 초과하였습니다.\n문자 발송은 1회 당 최대 10건까지 발송 가능합니다.'
					this.$store.dispatch('common/showToast', { msg: msg })
					return
				} else {
					let isEquals = false
					this.sendList.forEach(element => {
						if (element.phone === item.phone) {
							isEquals = true
							const msg = '해당 고객은 이미 선택하였습니다.'
							this.$store.dispatch('common/showToast', { msg: msg })
							return
						}
					})

					if (!isEquals) {
						this.$refs.table.select(item, event)
						this.sendList.push(item)
						this.sendSelected.push(item)
					}
					this.sendListCount = this.sendList.length
				}
			} else {
				this.$refs.table.select(item, event)
				this.sendList.forEach((element, index) => {
					if (element.phone === item.phone) {
						this.sendList.pop(index)
					}
				})
				this.sendListCount = this.sendList.length
			}
		},
		itemUnchecked(item, event) {
			if (!event) {
				let removeItem = this.sendList.find(sendItem => {
					if (item.phone === sendItem.phone) return sendItem
				})
				let removeIndex = this.sendList.indexOf(removeItem)
				if (removeIndex > -1) this.sendList.splice(removeIndex, 1)
				this.sendListCount = this.sendList.length

				let removeCustomerItem = this.selected.find(selectedItem => {
					if (item.phone === selectedItem.phone) return selectedItem
				})
				let removeCustomerIndex = this.selected.indexOf(removeCustomerItem)
				if (removeCustomerIndex > -1)
					this.selected.splice(removeCustomerIndex, 1)
			}
		},
		masking(value, type) {
			if (type === 'phone') {
				// 전화번호 마스킹 : 010-0000-0000 -> 010-****-0000
				let phoneMatchValue = value.match(/\d{2,3}-\d{3,4}-\d{4}/gi)
				if (/-[0-9]{3}-/.test(phoneMatchValue)) {
					value = value
						.toString()
						.replace(
							phoneMatchValue,
							phoneMatchValue.toString().replace(/-[0-9]{3}-/g, '-***-')
						)
				} else if (/-[0-9]{4}-/.test(phoneMatchValue)) {
					value = value
						.toString()
						.replace(
							phoneMatchValue,
							phoneMatchValue.toString().replace(/-[0-9]{4}-/g, '-****-')
						)
				}
			} else if (type === 'name') {
				let fName = value.substring(0, 1)
				let mName = value.substring(1, value.length - 1)
				let lName = value.substring(value.length - 1, value.length)
				let maskingChr = ''
				for (let i = 0; i < mName.length; i++) {
					maskingChr += '*'
				}
				value = fName + maskingChr + lName
			}
			return value
		},
		onScroll(e) {
			this.offsetTop = e.target.scrollTop

			if (typeof window === 'undefined') return
			const top = window.pageYOffset || this.offsetTop || 0
			this.fab = top > 20

			this.offsetTop > 140
				? (this.isTabActive = true)
				: (this.isTabActive = false)

			if (!this.loading) {
				let maxHeight = e.target.scrollHeight - 500
				let screenHeight = e.target.clientHeight + this.offsetTop
				if (screenHeight >= maxHeight) {
					if (this.pagination.totalPage > this.pagination.currentPage) {
						this.search()
					}
				}
			}
		},
		search(flag) {
			this.loading = true
			this.loadingText = '데이터를 불러오는 중입니다.'
			if (isEmpty(flag)) {
				this.pagination.currentPage++
			} else {
				this.selected = []
				this.customer = []
				this.pagination.totalCount = 0
				this.pagination.currentPage = 1

				if (flag === 'click') {
					this.isSearch = true
					this.isSearch = isEmpty(this.searchWord) ? false : true
				} else if (flag === 'refresh') {
					this.isSearch = false
					this.searchWord = ''
				}
			}

			mgntCustomerPosts({
				stdYm: moment().format('YYYYMM'),
				deptCd: this.deptCd,
				codyId: this.codyId,
				searchVal: this.searchWord,
				pageNo: this.pagination.currentPage,
				pageSize: this.pagination.pageSize
			}).then(response => {
				if (response.code === 200) {
					response.resultObject.customerList.forEach(row => {
						this.customer.push(row)
					})
					this.pagination.totalCount = response.resultObject.pageInfo.totalCount
					this.pagination.totalPage = Math.ceil(
						response.resultObject.pageInfo.totalCount /
							response.resultObject.pageInfo.pageSize
					)
				} else {
					this.pagination.totalCount = 0
					this.pagination.totalPage = 0
				}

				if (this.pagination.totalCount === 0) {
					this.loadingText = '등록된 관리고객이 없습니다.'
				}

				this.loading = false
			})
		},
		smsSend() {
			this.$log(this.sendList.length)
			if (this.sendList.length === 0) {
				const msg = '대상을 선택해 주세요.'
				this.showSnackbar({ msg: msg })
			} else {
				let paramsNumber = []
				let paramsMessage =
					'[코웨이] 고객님, 안녕하세요!\n코웨이의 제품정보를 안내해 드립니다.\n▶정보보기 : ' +
					this.shareUrl
				this.sendList.forEach(select => {
					if (!isEmpty(select.phone)) {
						paramsNumber.push(select.phone)
					}
				})
				let requestValue = {
					id: 'SMS',
					param: {
						number: paramsNumber.toString(),
						message: paramsMessage
					}
				}

				// eslint-disable-next-line no-console
				console.log(requestValue)

				this.$gtag.event('recommend_product_share_sms', {
					event_category: this.decCodyId,
					event_label: this.$route.query.boardId + '_' + this.title
				})

				/* eslint-disable */
				if(typeof BMManager !== 'undefined') {
					BMManager.executeTaskJSB(JSON.stringify(requestValue))
				}
			}
		}
	}
}
</script>

<style scoped lang="scss"></style>
