<template>
	<v-checkbox
		v-model="currentValue"
		:label="label"
		:disabled="disabled"
		hide-details
	/>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			required: true
		},
		checked: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			currentValue: this.checked
		}
	},
	watch: {
		checked(checked) {
			this.currentValue = checked
		},
		currentValue(value) {
			this.$emit('checked', value)
		}
	}
}
</script>
