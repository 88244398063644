import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/plugins/i18n'
import { isProduction } from '@/utils'
import styleOptions from '@/assets/styles/vuetify-theme'

Vue.use(Vuetify)
Vuetify.config.silent = isProduction()

const currentLocale = process.env.VUE_APP_I18N_LOCALE || 'en'

export default new Vuetify({
	...styleOptions,
	lang: {
		locales: { currentLocale },
		current: currentLocale,
		t: (key, ...params) => i18n.t(key, params)
	}
})
