import api from '@/apis/config'
// import { get } from 'core-js/fn/dict'

const { POST, GET, DELETE, fileDownloadGET, request } = api

/**
 * 신규 Guide를 등록한다
 * @param {Object} envIssue : guide 정보
 */
export const login = async user => {
	let url = process.env.VUE_APP_API_URL + '/login'

	// eslint-disable-next-line no-console
	console.log(user.user)
	const response = await POST(url, user.user)
	return response
}

export const getUserInfoByToken = async token => {
	let url = process.env.VUE_APP_API_URL + '/user'
	// eslint-disable-next-line no-console
	// console.log('token :::::::::: ', token)
	//method, url, headers, params, noLoadingOverlay
	let headers = { Authorization: token.token }
	const response = await request('GET', url, headers)
	return response
}

export const menuList = async parentId => {
	let url = process.env.VUE_APP_API_URL + '/menuList'

	const response = await GET(url, parentId)
	// const response = await request('GET', url, headers, parentId)
	return response
}

/**	공통 코드 조회
	@param {Object} groupCode : 그룹 코드 명 (contentCode1 : 제품추천카테고리, codyCode1 : 코디 문의유형)
*/

export const groupCodeList = async groupCode => {
	let url = process.env.VUE_APP_API_URL + `/common/code/${groupCode.code}`

	const response = await GET(url)
	return response
}

/**	특수 코드값 조회
	@param {Object} groupCode : 그룹 코드 명 (contentCode1 : 제품추천카테고리, codyCode1 : 코디 문의유형)
*/

export const groupExtCodeList = async groupCode => {
	let subcode = ''
	if (groupCode.subcode) subcode = '?user01=' + groupCode.subcode

	// eslint-disable-next-line
	let url = process.env.VUE_APP_API_URL + `/common/codeExt/${groupCode.code}` + subcode

	const response = await GET(url)
	return response
}

export const uploadEditorFile = async uploadFileList => {
	let url = process.env.VUE_APP_API_URL + '/uploadEditorFile'

	const response = await POST(url, uploadFileList.formData, {})
	return response
}

/**	대표이미지 등록 ( OG Image )
	@param {Object} uploadFile : 대표이미지
*/

export const uploadTitleImage = async uploadFile => {
	let url = process.env.VUE_APP_API_URL + '/uploadTitleImage'

	const response = await POST(url, uploadFile.formData, {})
	return response
}

/**
 * 첨부파일을 삭제한다
 */
export const attachFileDelete = async fileId => {
	let url = process.env.VUE_APP_API_URL + '/fileDelete/' + fileId

	const response = await DELETE(url, {})
	return response
}

/**
 * 첨부파일을 다운로드한다
 */
export const fileDownload = async fileInfo => {
	// eslint-disable-next-line no-console
	console.log('file download fileid :::::::::::::::::: ', fileInfo.fileId)
	// eslint-disable-next-line no-console
	console.log('file download fileName :::::::::::::::::: ', fileInfo.fileName)
	let url = process.env.VUE_APP_API_URL + '/fileDownload/' + fileInfo.fileId

	const response = await fileDownloadGET(url)
	return response
}

/**
 * 사번을 복호화 한다
 * @param {Object} userNo : 사번
 */
export const decCodyNo = async userNo => {
	let url = process.env.VUE_APP_API_URL + '/common/decCodyNo/' + userNo

	const response = await GET(url)
	return response
}
