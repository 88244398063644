<template>
	<v-dialog
		v-model="open"
		@click:outside="clickOutside"
		:persistent="persistent"
		:max-width="maxwidth"
	>
		<v-card>
			<v-card-title v-if="dialogTitle" v-html="dialogTitle" />
			<v-card-text v-if="dialogText" v-html="dialogText" />
			<slot name="subText" />
			<v-card-actions>
				<div class="btn_wrap">
					<AButton
						color="primary"
						v-if="buttonText"
						:title="buttonText"
						@click="clickButton"
					/>
					<AButton
						v-if="subButtonText"
						:title="subButtonText"
						@click="clickSubButton"
						color="default"
					/>
				</div>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		dialogTitle: String,
		dialogText: String,
		buttonText: String,
		subButtonText: String,
		persistent: Boolean,
		maxwidth: Number
	},
	data: () => ({
		open: false
	}),
	methods: {
		clickButton() {
			this.$emit('confirm')
		},
		clickSubButton() {
			this.$emit('cancel')
		},
		clickOutside() {
			if (!this.persistent) this.$emit('cancel')
		}
	}
}
</script>
