<template>
	<v-app>
		<!-- <MAppBar dark class="primary" /> -->
		<router-view :key="$route.fullPath" />
		<MSpinner />
	</v-app>
</template>

<script>
import MSpinner from '@/components/molecules/MSpinner'

export default {
	name: 'App',
	components: {
		MSpinner
	}
}
</script>

<style scoped>
.v-main {
	background: #f7f7f7;
}
</style>
