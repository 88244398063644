<template>
	<div :class="deliverdClass">
		<v-menu
			v-model="menu"
			ref="menu"
			min-width="100px"
			transition="scale-transition"
			:close-on-content-click="contentClose"
			offset-y
		>
			<template v-slot:activator="{ on, attrs }">
				<!-- <v-text-field
					v-model="currentDate"
					prepend-icon="mdi-calendar"
					readonly
					:outlined="outline"
					v-on="on"
					v-bind="attrs"
				/> -->
				<v-text-field
					hide-details="auto"
					v-model="currentDate"
					:label="`${label}`"
					v-bind="attrs"
					append-icon="mdi-calendar-month-outline"
					dense
					color="#222"
					outlined
					readonly
					single-line
					v-on="on"
					:disabled="disabled"
				></v-text-field>
			</template>
			<v-date-picker
				:class="menuClass"
				hide-details="auto"
				v-model="currentDate"
				:day-format="date => new Date(date).getDate()"
				:min="min"
				:max="max"
				@click="$refs.menu.save(currentDate)"
				@input="menu = false"
				no-title
				scrollable
				:type="type"
			/>
		</v-menu>
	</div>
</template>

<script>
import moment from 'moment'

export default {
	props: {
		date: {
			type: [String, Date],
			default: moment(new Date()).format('YYYY-MM-DD')
		},
		type: {
			type: String,
			default: 'date'
		},
		menuClass: {
			type: String
		},
		deliverdClass: String,
		contentClose: {
			type: Boolean,
			default: false
		},
		min: String,
		max: String,
		outline: {
			type: Boolean,
			default: false
		},
		textFiledClass: String,
		dense: {
			type: Boolean,
			default: false
		},
		label: String,
		prependIcon: {
			type: String,
			default: ''
		},
		appendIcon: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			currentDate: this.date,
			menu: false
		}
	},
	watch: {
		date(date) {
			this.currentDate = date
		},
		currentDate(date) {
			this.$emit('date', date)
		}
	},
	mounted() {
		if (this.type == 'month') {
			this.date = moment(new Date()).format('YYYY-MM')
		}
	}
}
</script>
