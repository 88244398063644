import { sleep } from '@/utils/sleep'

const state = {
	user: {
		accessToken: null,
		expiresAt: null
	},
	loading: false,
	snackbar: { status: false, msg: '', color: 'error' },
	toast: { status: false, msg: '', color: 'error' },
	frontTopBarTitle: ''
}

const getters = {
	accessToken: state => state.user.accessToken,
	showSnackbar: state => state.snackbar,
	showToast: state => state.toast,
	frontTopBarTitle: state => state.frontTopBarTitle
}

const actions = {
	async showSnackbar({ commit }, params) {
		const payload = Object.assign({ color: 'error' }, params)
		commit('setSnackBar', payload)
		await sleep(5000)
		commit('closeSnackbar')
	},
	async showToast({ commit }, params) {
		const payload = Object.assign({ color: 'dark' }, params)
		commit('setToast', payload)
		await sleep(5000)
		commit('closeToast')
	}
}

const mutations = {
	setLoading(state, value) {
		state.loading = value
	},
	login(state, accessToken) {
		state.user.accessToken = accessToken
	},
	logout(state) {
		state.user.accessToken = null
	},
	setSnackBar(state, payload) {
		const { msg, color } = payload
		state.snackbar.msg = msg
		state.snackbar.color = color
		state.snackbar.status = true
	},
	closeSnackbar(state) {
		state.snackbar.status = false
	},
	setToast(state, payload) {
		const { msg, color } = payload
		state.toast.msg = msg
		state.toast.color = color
		state.toast.status = true
	},
	closeToast(state) {
		state.toast.status = false
	},
	setFrontTopBarTitle: (state, payload) => {
		state.frontTopBarTitle = payload.frontTopBarTitle
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
