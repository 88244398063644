export default [
	{
		path: '/',
		redirect: '/login',
		meta: {
			isPublic: true,
			hideAppBar: true
		}
	},
	{
		path: '/login',
		name: 'login',
		redirect: '/front/error/404'
	}
]
