import Vue from 'vue'
import VueRouter from 'vue-router'
import common from './common.router'
import store from '@/store'
import publish from './publish.router'

import front from './front.router'
import commission from './commission.router'
import empInsurance from './empInsurance.router'
import codyPool from './codyPool.router'
import cancellation from './termination.router'
import publish_cancellation from './publish.cancellation.router'
Vue.use(VueRouter)

// 모드에 따른 라우터 포함 처리
import { isProduction } from '@/utils/index'
let routings = [
	...common,
	...front,
	...commission,
	...empInsurance,
	...codyPool,
	...cancellation
]
if (!isProduction()) {
	routings.push(...publish)
	routings.push(...publish_cancellation)
}

const routes = [
	...routings
	// ,
	// {
	// 	path: '/error/:errorCode',
	// 	name: 'error',
	// 	component: () => import('@/views/error/ErrorPage.vue'),
	// 	// component: () => import('@/views/error/AdminMessage.vue'),
	// 	meta: {
	// 		isPublic: true,
	// 		hideAppBar: true
	// 	}
	// }
	// ,
	// {
	// 	path: '/*',
	// 	name: 'notFound',
	// 	redirect: '/error/404'
	// },
	// {
	// 	path: '/*',
	// 	name: 'notFound',
	// 	redirect: '/error/500'
	// }
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		return savedPosition ? savedPosition : { x: 0, y: 0 }
	}
})

router.beforeEach((to, from, next) => {
	// eslint-disable-next-line no-console
	//console.log('to.meta.isPublic ::::::::::::::: ', to.meta.isPublic)
	// eslint-disable-next-line no-console
	//console.log(
	// 	'store.getters[common/accessToken] ::::::::::::::: ',
	// 	store.getters['common/accessToken']
	// )
	// eslint-disable-next-line no-console
	//console.log('next::::::::::::::: ', next)
	// eslint-disable-next-line no-console
	// console.log(
	// 	'loacalStorege token ::::::::::::::: ',
	// 	localStorage.getItem('access_token')
	// )
	const isValid = to.meta.isPublic || store.getters['common/accessToken']

	if (isValid) return next()

	redirectToLogin(to.fullPath)

	function redirectToLogin(redirectFrom) {
		next({ name: 'login', query: { redirectFrom } })
	}
})

export default router
