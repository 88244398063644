import { sleep } from '@/utils/sleep'

const state = {
	snackbar: { status: false, msg: '', color: 'error' }
}

const getters = {
	showSnackbar: state => state.snackbar
}

const actions = {
	async showSnackbar({ commit }, params) {
		const payload = Object.assign({ color: 'error' }, params)
		commit('setSnackBar', payload)
		await sleep(5000)
		commit('closeSnackbar')
	}
}

const mutations = {
	setSnackBar(state, payload) {
		const { msg, color } = payload
		state.snackbar.msg = msg
		state.snackbar.color = color
		state.snackbar.status = true
	},
	closeSnackbar(state) {
		state.snackbar.status = false
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
