<template>
	<v-overlay :value="loading" color="white">
		<v-progress-circular :value="size" :color="color" indeterminate />
	</v-overlay>
</template>

<script>
import { mapState } from 'vuex'

export default {
	props: {
		color: {
			type: String,
			default: 'primary'
		},
		size: {
			type: Number,
			default: 80
		}
	},
	computed: mapState({
		loading: state => state.common.loading
	})
}
</script>
