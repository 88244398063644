<template>
	<v-btn
		:height="height"
		:type="type"
		:slot="slotName"
		:color="color"
		:disabled="disabled"
		:readonly="readonly"
		:fab="fab"
		:text="text"
		:small="small"
		:x-small="xSmall"
		:large="large"
		:x-large="xLarge"
		:block="block"
		:outlined="outlined"
		:depressed="depressed"
		:dark="dark"
		:rounded="rounded"
		@click="onClick"
		@mouseover="onMouseover"
		@mouseout="onMouseout"
	>
		{{ title }}
		<v-icon v-if="icon" small class="pl-2">{{ icon }}</v-icon>
	</v-btn>
</template>

<script>
export default {
	props: {
		height: String,
		type: {
			type: String,
			default: 'button'
		},
		slotName: String,
		color: {
			type: String,
			default: 'defalut'
		},
		title: String,
		icon: String,
		disabled: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		fab: {
			type: Boolean,
			default: false
		},
		text: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		xSmall: {
			type: Boolean,
			default: false
		},
		large: {
			type: Boolean,
			default: false
		},
		xLarge: {
			type: Boolean,
			default: false
		},
		block: {
			type: Boolean,
			default: false
		},
		outlined: {
			type: Boolean,
			default: false
		},
		depressed: {
			type: Boolean,
			default: true
		},
		dark: {
			type: Boolean,
			default: false
		},
		rounded: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		onClick() {
			this.$emit('click')
		},
		onMouseover() {
			this.$emit('mouseover')
		},
		onMouseout() {
			this.$emit('mouseout')
		}
	}
}
</script>
