import AButton from '@/components/atoms/AButton'
import AAlert from '@/components/atoms/AAlert.vue'
import ADialog from '@/components/atoms/ADialog'
import ACheckbox from '@/components/atoms/ACheckbox'
import ADatePicker from '@/components/atoms/ADatePicker'
import ATimePicker from '@/components/atoms/ATimePicker'
import ASelect from '@/components/atoms/ASelect'
import MSpinner from '@/components/molecules/MSpinner'
import MListScroll from '@/components/molecules/MListScroll'
import CustomTopInforBar from '@/components/CustomTopInforBar'
import MgntCustomerPopup from '@/views/front/popup/mgntCustomerPopup'

export default {
	components: {
		AButton,
		AAlert,
		ADialog,
		ACheckbox,
		ADatePicker,
		ATimePicker,
		ASelect,
		MSpinner,
		MListScroll,
		CustomTopInforBar,
		MgntCustomerPopup
	}
}
