/* eslint-disable prettier/prettier */
import '@babel/polyfill'
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import routerReferer from '@tozd/vue-router-referer'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import { sync } from 'vuex-router-sync'
import '@/plugins/alert'
import '@/utils/log'
//import '@/registerServiceWorker'
import base from '@/components/base'
import common from '@/utils/common'
import VueGoogleCharts from 'vue-google-charts'
import { getUserInfoByToken } from '@/apis/common.api'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import VueGtag from 'vue-gtag'

//vant
import { IndexBar, IndexAnchor } from 'vant'
import 'vant/lib/index.css'
import { List } from 'vant';
import { CountDown } from 'vant';

import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies'

// 사이드 네비게이션 추가
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
// 서명패드
import VueSignaturePad from 'vue-signature-pad'
 
Vue.use(VueSignaturePad)
Vue.use(CountDown)
Vue.use(List)
Vue.use(IndexBar)
Vue.use(IndexAnchor)
Vue.use(VueSidebarMenu)
Vue.use(VueCookies)
Vue.mixin(common)
Vue.mixin(base)
Vue.config.productionTip = false
Vue.use(VueGoogleCharts)
Vue.use(routerReferer)
sync(store, router)
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
  })
Vue.use(VueGtag, {
    config: {
        // Google Analytics의 Tracking ID를 넣어준다
        id: 'UA-174643619-3', 
    }
})

new Vue({
    router,
    store,
    vuetify,
    i18n,
    beforeCreate() {
        let token = localStorage.getItem('access_token')

        if (!this._router.history._startLocation.startsWith('/board/error')
            && this._router.history._startLocation.includes('/board')) {
            getUserInfoByToken({ token }).then(res => {
                if (res.status === 200) {
                    this.$store.commit('common/login', token)
                } else {
                    this._router.push('/login')
                }
            })
        } 
    },
    render: h => h(App)
}).$mount('#app')
