export default [
	/************ publish_Admin ************/
	{
		path: '/publish/admin',
		name: 'publishAdmin',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/publish/admin/SamplePage.vue'),
		children: [
			/*환경 어드민*/
			{
				path: '/publish/admin/boardEnv/boardEnvList',
				name: 'publishEnvironmentList',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/admin/boardEnv/boardEnvList.vue')
			},
			{
				path: '/publish/admin/boardEnv/boardEnvCreate',
				name: 'publishEnvironmentCreate',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/admin/boardEnv/boardEnvCreate.vue')
			},
			{
				path: '/publish/admin/boardEnv/boardEnvEdit',
				name: 'publishEnvironmentEdit',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/admin/boardEnv/boardEnvEdit.vue')
			},
			/*공지사항 어드민*/
			{
				path: '/publish/admin/boardNotice/boardNoticeList',
				name: 'publishNoticeList',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/admin/boardNotice/boardNoticeList.vue')
			},
			{
				path: '/publish/admin/boardNotice/boardNoticeCreate',
				name: 'publishNoticeCreate',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/admin/boardNotice/boardNoticeCreate.vue')
			},
			{
				path: '/publish/admin/boardNotice/boardNoticeEdit',
				name: 'publishNoticeEdit',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/admin/boardNotice/boardNoticeEdit.vue')
			},
			/**제품추천 어드민 */
			{
				path: '/publish/admin/boardSale/boardSaleList',
				name: 'publishboardSaleList',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/admin/boardSale/boardSaleList.vue')
			},
			{
				path: '/publish/admin/boardSale/SalesContentCreate',
				name: 'publishSalesContentCreate',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/admin/boardSale/SalesContentCreate.vue')
			},
			/*1:1코디 어드민*/
			{
				path: '/publish/admin/boardCody/boardCodyList',
				name: 'publishCodyList',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/admin/boardCody/boardCodyList.vue')
			},
			{
				path: '/publish/admin/boardCody/boardCodyEdit',
				name: 'publishCodyEdit',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/admin/boardCody/boardCodyEdit.vue')
			},
			{
				path: '/publish/admin/boardCody/boardCodyEditConfirm',
				name: 'publishCodyEditConfirm',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/admin/boardCody/boardCodyEditConfirm.vue')
			},
			{
				path: '/publish/admin/errorPage/Sessionout',
				name: 'publishErrorSessionout',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/admin/errorPage/Sessionout.vue')
			},
			{
				path: '/publish/admin/errorPage/Error404',
				name: 'publishError404',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/admin/errorPage/Error404.vue')
			},
			{
				path: '/publish/admin/errorPage/Error500',
				name: 'publishError500',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/admin/errorPage/Error500.vue')
			},
			{
				path: '/publish/admin/AdminAuthority',
				name: 'publishadmin-authority',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/admin/AdminAuthority.vue')
			},
			{
				path: '/publish/admin/AdminMessage',
				name: 'publishadmin-message',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/admin/AdminMessage.vue')
			}
		]
	},
	/************ publish_Commission ************/
	{
		path: '/publish/commission',
		name: 'publishCommission',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/publish/commission/SamplePage.vue'),
		children: [
			{
				path: '/publish/commission/List',
				name: 'List',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/commission/List.vue')
			},
			{
				path: '/publish/commission/DetailList',
				name: 'DetailList',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/commission/DetailList.vue')
			},
			{
				path: '/publish/commission/MonthlyPayment',
				name: 'MonthlyPayment',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/commission/MonthlyPayment.vue')
			},
			{
				path: '/publish/commission/DeductDetailList',
				name: 'DeductDetailList',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/commission/DeductDetailList.vue')
			}
		]
	},
	/************ publish_Front ************/
	{
		path: '/publish/front',
		name: 'publishFront',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/publish/front/SamplePage.vue'),
		children: [
			{
				path: '/publish/front/UiGuide',
				name: 'UiGuide',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/front/UiGuide.vue')
			},
			{
				path: '/publish/commonGuide',
				name: 'commonGuide',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/commonGuide.vue')
			},
			//sampleMessage = 에러페이지
			{
				path: '/publish/front/SampleMessage',
				name: 'SampleMessage',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/front/SampleMessage.vue')
			},
			// 210205
			{
				path: '/html/list',
				name: 'PublishList',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/PublishList.vue')
			},
			{
				path: '/publish/front/SamplePopup',
				name: 'SamplePopup',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/front/SamplePopup.vue')
			},
			{
				path: '/publish/front/environment',
				name: 'environment',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/front/environment.vue')
			},
			{
				path: '/publish/front/EnvironmentEdit',
				name: 'EnvironmentEdit',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/front/EnvironmentEdit.vue')
			},
			{
				path: '/publish/front/full_pop02',
				name: 'front_full_pop02',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/front/full_pop02.vue')
			},
			{
				path: '/publish/front/full_pop03',
				name: 'front_full_pop03',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/front/full_pop03.vue')
			}
		]
	},
	/************ publish_FrontMob ************/
	{
		path: '/publish/front_mob',
		name: 'publishFront_mob',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/publish/front_mob/SamplePage.vue'),
		children: [
			/*공통 에러메세지*/
			{
				path: '/publish/front_mob/errorMessage',
				name: 'mobErrorMessage',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/front_mob/errorMessage.vue')
			},
			/*환경*/
			{
				path: '/publish/front_mob/boardEnv/environmentList',
				name: 'mobEnvironmentList',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front_mob/boardEnv/environmentList.vue')
			},
			{
				path: '/publish/front_mob/boardEnv/environmentEdit',
				name: 'mobEnvironmentEdit',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front_mob/boardEnv/environmentEdit.vue')
			},
			/*제품추천*/
			{
				path: '/publish/front_mob/boardSale/saleList',
				name: 'saleList',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front_mob/boardSale/saleList.vue')
			},
			{
				path: '/publish/front_mob/boardSale/saleEdit',
				name: 'saletEdit',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front_mob/boardSale/saleEdit.vue')
			},
			/*공지사항*/
			{
				path: '/publish/front_mob/boardNotice/noticeList',
				name: 'noticeList',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front_mob/boardNotice/noticeList.vue')
			},
			{
				path: '/publish/front_mob/boardNotice/noticeEdit',
				name: 'noticeEdit',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front_mob/boardNotice/noticeEdit.vue')
			},
			{
				path: '/sample/samplemain',
				name: 'samplemain',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/sample/SampleMain.vue')
			}
		]
	},
	/************ publish_CodyMatching ************/
	{
		path: '/publish/codyPool',
		name: 'publishMatching',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/publish/codyPool/SamplePage.vue'),
		children: [
			{
				path: '/publish/codyPool/matMain',
				name: 'matMain',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/codyPool/matMain.vue')
			},
			{
				path: '/publish/codyPool/matApplyOld',
				name: 'matApplyOld',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/codyPool/matApplyOld.vue')
			},
			{
				path: '/publish/codyPool/matApplyNew',
				name: 'matApplyNew',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/codyPool/matApplyNew.vue')
			},
			{
				path: '/publish/codyPool/modal',
				name: 'modal',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/codyPool/modal.vue')
			},
			{
				path: '/publish/codyPool/toast',
				name: 'toast',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/codyPool/toast.vue')
			},
			{
				path: '/publish/codyPool/full_pop01',
				name: 'codyPool_full_pop01',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/codyPool/full_pop01.vue')
			},
			{
				path: '/publish/codyPool/full_pop02_01',
				name: 'codyPool_full_pop02_01',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/codyPool/full_pop02_01.vue')
			},
			{
				path: '/publish/codyPool/full_pop02_02',
				name: 'codyPool_full_pop02_02',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/codyPool/full_pop02_02.vue')
			},
			{
				path: '/publish/codyPool/full_pop02_03',
				name: 'codyPool_full_pop02_03',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/codyPool/full_pop02_03.vue')
			},
			{
				path: '/publish/codyPool/PopApplyDate',
				name: 'PopApplyDate',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/codyPool/PopApplyDate.vue')
			},
			{
				path: '/publish/codyPool/PopApplyUser',
				name: 'PopApplyUser',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/codyPool/PopApplyUser.vue')
			}
		]
	},
	/************ publish_Smart ************/
	{
		path: '/publish/smart',
		name: 'publishSmart',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/publish/smart/SamplePage.vue'),
		children: [
			{
				path: '/publish/smart/smartMain',
				name: 'smartMain',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/smart/smartMain.vue')
			}
		]
	},
	/************ publish_boardApp ************/
	{
		path: '/publish/boardApp',
		name: 'publishBoardApp',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/publish/boardApp/SamplePage.vue'),
		children: [
			{
				path: '/publish/boardApp/boardMain',
				name: 'boardMain',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/boardApp/boardMain.vue')
			},
			{
				path: '/publish/boardApp/boardMain_2',
				name: 'boardMain_2',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/boardApp/boardMain_2.vue')
			},
			{
				path: '/publish/boardApp/boardList',
				name: 'boardList',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/boardApp/boardList.vue')
			},
			{
				path: '/publish/boardApp/boardEnrollment',
				name: 'boardEnrollment',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/boardApp/boardEnrollment.vue')
			},
			{
				path: '/publish/boardApp/boardContents',
				name: 'boardContents',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/boardApp/boardContents.vue')
			},
			{
				path: '/publish/boardApp/boardContents_complete',
				name: 'boardContents_complete',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/boardApp/boardContents_complete.vue')
			},
			{
				path: '/publish/boardApp/boardUiGuide',
				name: 'boardUiGuide',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/boardApp/boardUiGuide.vue')
			}
		]
	},
	/************ 영업 캘린더 ************/
	{
		path: '/publish/saleCalendar',
		name: 'publishSaleCalendar',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/publish/saleCalendar/SamplePage.vue'),
		children: [
			{
				path: '/publish/saleCalendar/calendar',
				name: 'saleCalendarTest',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/saleCalendar/calendar.vue')
			},
			{
				path: '/publish/saleCalendar/guide',
				name: 'saleCalendarguide',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/saleCalendar/Guide.vue')
			},
			{
				path: '/publish/saleCalendar/bottom',
				name: 'saleCalendarbottom',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/saleCalendar/bottomSheet.vue')
			},
			{
				path: '/publish/saleCalendar/calendarPopup',
				name: 'calendarPopup',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/saleCalendar/calendarPopup.vue')
			},
			{
				path: '/publish/saleCalendar/customerList',
				name: 'customerList',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/saleCalendar/customerList.vue')
			},
			{
				path: '/publish/saleCalendar/customerList_popup',
				name: 'customerList_popup',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/saleCalendar/customerList_popup.vue')
			},
			{
				path: '/publish/saleCalendar/customerEnroll',
				name: 'customerEnroll',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/saleCalendar/customerEnroll.vue')
			},
			{
				path: '/publish/saleCalendar/customerInfo',
				name: 'customerInfo',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/publish/saleCalendar/customerInfo.vue')
			},
			{
				path: '/publish/saleCalendar/customerInfo_popup',
				name: 'customerInfo_popup',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/saleCalendar/customerInfo_popup.vue')
			},
			{
				path: '/publish/saleCalendar/customerInfoEdit',
				name: 'customerInfoEdit',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/saleCalendar/customerInfoEdit.vue')
			},
			{
				path: '/publish/saleCalendar/customerInfoEdit_gamang',
				name: 'customerInfoEdit_gamang',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/saleCalendar/customerInfoEdit_gamang.vue')
			},
			{
				path: '/publish/saleCalendar/scheduleEnroll',
				name: 'scheduleEnroll',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/saleCalendar/scheduleEnroll.vue')
			},
			{
				path: '/publish/saleCalendar/scheduleDetail',
				name: 'scheduleDetail',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/saleCalendar/scheduleDetail.vue')
			},
			{
				path: '/publish/saleCalendar/scheduleDetail_system',
				name: 'scheduleDetail_system',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/saleCalendar/scheduleDetail_system.vue')
			}
		]
	},
	//EmpInsuranceList 고용보험
	{
		path: '/publish/empInsuranceList',
		name: 'publishHire',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/publish/empInsuranceList/SamplePage.vue'),
		children: [
			{
				path: '/publish/empInsuranceList/EmpInsuranceList',
				name: 'pubEmpInsuranceList',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/empInsuranceList/EmpInsuranceList.vue')
			}
		]
	}
]
