const state = {
	codyId: ''
}

const getters = {
	codyId: state => state.codyId
}

const actions = {}

const mutations = {
	setCodyId: (state, payload) => {
		state.codyId = payload
		// eslint-disable-next-line no-console
		console.log('state.codyId :::::::::::::: ', state.codyId)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
