export default [
	{
		path: '/commission',
		name: 'commission',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/commission/Index.vue'),
		children: [
			{
				path: 'list',
				name: 'commissionList',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/commission/List.vue')
			},
			{
				path: 'detailList',
				name: 'commissionDetailList',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/commission/DetailList.vue')
			},
			{
				path: 'deductDetailList',
				name: 'commissinDeductDetailList',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/commission/DeductDetailList.vue')
			},
			{
				path: 'monthlyPayment',
				name: 'commissionMonthlyPayment',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/commission/MonthlyPayment.vue')
			}
		]
	}
]
