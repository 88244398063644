const state = {
	searchFilters: {
		categoryCd: '',
		schStartDate: '',
		schEndDate: '',
		exposureYn: '',
		topYn: '',
		searchWord: '',
		questionType: '',
		schIdNmType: 'nm',
		status: '',
		currentPage: 1
	},
	codyId: '',
	codyNm: '',
	deptCd: '',
	userType: '',
	sharedAccessYn: '',
	boardType: ''
}

const getters = {
	searchFilters: state => {
		return state.searchFilters
	},
	codyId: state => state.codyId,
	codyNm: state => state.codyNm,
	deptCd: state => state.deptCd,
	userType: state => state.userType,
	sharedAccessYn: state => state.sharedAccessYn,
	boardType: state => state.boardType
}

const actions = {}

const mutations = {
	setSearchFilters: (state, payload) => {
		state.searchFilters.categoryCd = payload.categoryCd
		state.searchFilters.exposureYn = payload.exposureYn
		state.searchFilters.topYn = payload.topYn
		state.searchFilters.schStartDate = payload.schStartDate
		state.searchFilters.schEndDate = payload.schEndDate
		state.searchFilters.searchWord = payload.searchWord
		state.searchFilters.schIdNmType = payload.schIdNmType
		state.searchFilters.status = payload.status
		state.searchFilters.questionType = payload.questionType
		state.searchFilters.currentPage = payload.currentPage

		// eslint-disable-next-line no-console
		console.log('state.searchFilters :::::::::::::: ', state.searchFilters)
	},
	setCodyId: (state, payload) => {
		state.codyId = payload
		// eslint-disable-next-line no-console
		console.log('state.codyId :::::::::::::: ', state.codyId)
	},
	setCodyNm: (state, payload) => {
		state.codyNm = payload
		// eslint-disable-next-line no-console
		console.log('state.codyNm :::::::::::::: ', state.codyNm)
	},
	setDeptCd: (state, payload) => {
		state.deptCd = payload
		// eslint-disable-next-line no-console
		console.log('state.deptCd :::::::::::::: ', state.deptCd)
	},
	setUserType: (state, payload) => {
		state.userType = payload
		// eslint-disable-next-line no-console
		console.log('state.userType :::::::::::::: ', state.userType)
	},
	setSharedAccessYn: (state, payload) => {
		state.sharedAccessYn = payload
		// eslint-disable-next-line no-console
		console.log('state.sharedAccessYn :::::::::::::: ', state.sharedAccessYn)
	},
	setBoardType: (state, payload) => {
		state.boardType = payload
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
