export default [
	{
		path: '/codyPool',
		name: 'codyPool',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/codyPool/Index.vue'),
		children: [
			{
				path: 'apply',
				name: 'codyPoolApply',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/codyPool/Apply.vue')
			},
			{
				path: 'applyDate',
				name: 'codyPoolApplyDate',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/codyPool/ApplyDate.vue')
			},
			{
				path: 'applyUser',
				name: 'codyPoolApplyUser',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/codyPool/ApplyUser.vue')
			}
		]
	}
]
