<template>
	<v-select
		v-model="selectedLists"
		:items="items"
		:item-text="itemText"
		:item-value="itemValue"
		:label="label"
		:multiple="multiple"
		outlined
		color="#222"
		dense
		hide-details="auto"
		single-line
		return-object
		@change="rtnValue"
	>
		<template v-slot:prepend-item v-if="isSelectAll">
			<v-list-item ripple @click="toggle">
				<v-list-item-action>
					<v-icon>{{ icon }}</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>
						{{ $t('common.word.selectAll') }}
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider class="mt-2" />
		</template>
	</v-select>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			required: true
		},
		label: {
			type: String,
			default: ''
		},
		multiple: {
			type: Boolean,
			default: false
		},
		isSelectAll: {
			type: Boolean,
			default: false
		},
		outline: {
			type: Boolean,
			default: false
		},
		dense: {
			type: Boolean,
			default: false
		},
		itemText: {
			type: String,
			default: ''
		},
		itemValue: {
			type: String,
			default: ''
		}
		// ,
		// setValue: {
		// 	type: Object,
		// 	defalut: {}
		// }
	},
	data: () => ({
		selectedLists: []
	}),
	mounted() {
		// this.$log('setValue :::::::::::::::: ', this.$props.setValue === {})
		if (this.$props.setValue === {} || this.$props.setValue === '') {
			this.$props.items.forEach(element => {
				if (element.value === this.$props.setValue.value) {
					if (this.$props.multiple !== true) this.selectedLists = element
				}
			})
		} else {
			if (this.$props.multiple !== true)
				this.selectedLists = this.$props.items[0]
		}
	},
	computed: {
		selectedAll() {
			return this.selectedLists.length === this.items.length
		},
		selected() {
			return this.selectedLists.length > 0 && !this.selectedAll
		},
		icon() {
			if (this.selectedAll) return 'mdi-close-box'
			if (this.selected) return 'mdi-minus-box'
			return 'mdi-checkbox-blank-outline'
		}
	},
	methods: {
		toggle() {
			// this.selectedLists = []
			this.$nextTick(() => {
				this.selectedLists = this.selectedAll ? [] : this.items.slice()
			})
		},
		rtnValue() {
			// this.$log(this.selectedLists)
			this.$emit('selectValue', this.selectedLists)
		}
	}
}
</script>
