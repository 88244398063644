<template>
	<v-dialog
		v-model="value"
		:persistent="disableOutsideCilck"
		:fullscreen="fullscreen"
		:hide-overlay="hideOverlay"
		:transition="transition"
		:max-width="maxwidth"
		:visibleAction="visibleAction"
		@click:outside="
			disableOutsideCilck
				? () => {}
				: $emit($listeners.modifyVisible ? 'modifyVisible' : 'input', false)
		"
	>
		<v-card>
			<v-card-title v-if="dialogTitle" v-html="dialogTitle" />
			<v-card-text v-if="dialogText" v-html="dialogText" />
			<slot name="subText" />
			<slot name="subForm"></slot>
			<v-card-actions>
				<div class="btn_wrap">
					<AButton
						color="default"
						v-if="subButtonText"
						:title="subButtonText"
						@click="subButtonClick"
					/>
					<AButton
						v-if="buttonText"
						:title="buttonText"
						@click="buttonClick"
						color="primary"
					/>
				</div>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import AButton from '@/components/atoms/AButton'

export default {
	props: {
		value: Boolean,
		dialogTitle: String,
		dialogText: String,
		buttonText: String,
		subButtonText: String,
		fullscreen: Boolean,
		hideOverlay: String,
		transition: String,
		disableOutsideCilck: {
			type: Boolean,
			default: false
		},
		maxwidth: Number,
		visibleAction: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			currentValue: this.value
		}
	},
	watch: {
		value(value) {
			this.currentValue = value
		},
		currentValue(value) {
			if (value && this.visibleAction) {
				this.$emit('visibleFunction')
			}
		}
	},
	components: {
		AButton
	},
	methods: {
		buttonClick() {
			this.$emit('buttonFunction')
		},
		subButtonClick() {
			this.$emit('subButtonFunction')
		}
	}
}
</script>
