export default [
	/** publishAdmin **/
	{
		path: '/publish/cancellation',
		name: 'cancellation',
		meta: {
			isPublic: true
		},
		component: () =>
			import('@/views/publish/front/cancellation/SamplePage.vue'),
		children: [
			{
				path: '/html/cancelList',
				name: 'cancelList',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front/cancellation/CancelList.vue')
			},

			{
				path: '/html/cancelState',
				name: 'cancelState',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front/cancellation/CancelState.vue')
			},
			{
				path: '/html/cancelState2',
				name: 'cancelState2',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front/cancellation/CancelState2.vue')
			},
			{
				path: '/html/cancelRequest',
				name: 'cancelRequest',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front/cancellation/CancelRequest.vue')
			},
			{
				path: '/html/cancelApply',
				name: 'cancelApply',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front/cancellation/CancelApply.vue')
			},

			{
				path: '/html/cancelModal',
				name: 'cancelModal',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front/cancellation/CancelModal.vue')
			},
			{
				path: '/html/cancelModal_00',
				name: 'cancelModal_00',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front/cancellation/CancelModal_00.vue')
			},
			{
				path: '/html/cancelConfirm',
				name: 'cancelConfirm',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/publish/front/cancellation/CancelConfirm.vue')
			}
		]
	}
]
