import { mapActions } from 'vuex'
import moment from 'moment'
export default {
	computed: {},
	filters: {
		currency: function(val) {
			let num = new Number(val)
			return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
		},
		dateCommaFormat: function(val) {
			return moment(val).format('YYYY.MM.DD')
		},
		dateCommaFormatForRegi: function(val) {
			return moment(val)
				.add(2, 'days')
				.format('YYYY.MM.DD')
		},
		toFixedThree: function(val) {
			let num = new Number(val)
			return num.toFixed(3).replace(/\.?0+$/, '')
		}
	},
	methods: {
		...mapActions(['showSnackbar', 'common/showToast']),
		thousand: value => {
			if (!value) return value
			if (isNaN(value)) return value
			return parseFloat(value).toLocaleString()
		},
		toFixed: (value, decimalPoint) => {
			if (!value) return value
			if (isNaN(value)) return value
			return parseFloat(value).toFixed(decimalPoint)
		},
		currency: value => {
			return this.thousand(this.toFixed(value, 0))
		},
		nvl: value => {
			if (value == '' || value == null) {
				return false
			} else {
				return true
			}
		},
		getMask(phoneNumber) {
			if (!phoneNumber) return phoneNumber
			phoneNumber = phoneNumber.replace(/[^0-9/s]/g, '')

			let res = ''
			if (phoneNumber.length < 3) {
				res = phoneNumber
			} else {
				if (phoneNumber.substr(0, 2) == '02') {
					if (phoneNumber.length <= 5) {
						//02-123-5678
						res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3)
					} else if (phoneNumber.length > 5 && phoneNumber.length <= 9) {
						//02-123-5678
						res =
							phoneNumber.substr(0, 2) +
							'-' +
							phoneNumber.substr(2, 3) +
							'-' +
							phoneNumber.substr(5)
					} else if (phoneNumber.length > 9) {
						//02-1234-5678
						res =
							phoneNumber.substr(0, 2) +
							'-' +
							phoneNumber.substr(2, 4) +
							'-' +
							phoneNumber.substr(6)
					}
				} else {
					if (phoneNumber.length < 8) {
						res = phoneNumber
					} else if (phoneNumber.length == 8) {
						res = phoneNumber.substr(0, 4) + '-' + phoneNumber.substr(4)
					} else if (phoneNumber.length == 9) {
						res =
							phoneNumber.substr(0, 3) +
							'-' +
							phoneNumber.substr(3, 3) +
							'-' +
							phoneNumber.substr(6)
					} else if (phoneNumber.length == 10) {
						res =
							phoneNumber.substr(0, 3) +
							'-' +
							phoneNumber.substr(3, 3) +
							'-' +
							phoneNumber.substr(6)
					} else if (phoneNumber.length > 10) {
						//010-1234-5678
						res =
							phoneNumber.substr(0, 3) +
							'-' +
							phoneNumber.substr(3, 4) +
							'-' +
							phoneNumber.substr(7)
					}
				}
			}

			return res
		}
	}
}
