export default [
	{
		path: '/front',
		name: 'front',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/front/frontContent.vue'),
		children: [
			{
				path: '/front/environmentList',
				name: 'frontEnvironmentList',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/front/boardEnv/frontEnvList.vue')
			},
			{
				path: '/front/environmentDetail',
				name: 'environmentDetail',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/front/boardEnv/frontEnvDetail.vue')
			},
			{
				path: '/front/latestEnvironmentDetail',
				name: 'latestEnvironmentDetail',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/front/boardEnv/frontEnvDetail.vue')
			},
			{
				path: '/front/error/:errorCode',
				name: 'frontError',
				component: () => import('@/views/error/FrontErrorPage.vue'),
				meta: {
					isPublic: true,
					hideAppBar: true
				}
			},
			{
				path: '/front/*',
				name: 'frontNotFound',
				redirect: '/front/error/404'
			},
			{
				path: '/front/*',
				name: 'front_system_error',
				redirect: '/front/error/500'
			}
		]
	},
	{
		path: '/frontapp',
		name: '/frontapp',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/front/frontContent.vue'),
		children: [
			{
				path: '/frontapp/environmentList',
				name: 'frontEnvironmentListApp',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/front/boardEnv/frontEnvList.vue')
			},
			{
				path: '/frontapp/environmentDetail',
				name: 'environmentDetailApp',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/front/boardEnv/frontEnvDetail.vue')
			},
			{
				path: '/frontapp/latestEnvironmentDetail',
				name: 'latestEnvironmentDetailApp',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/front/boardEnv/frontEnvDetail.vue')
			},
			{
				path: '/frontapp/codyContentMain',
				name: 'codyContentMain',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/front/boardCody/frontCodyMain.vue')
			},
			{
				path: '/frontapp/codyContentList',
				name: 'codyContentList',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/front/boardCody/frontCodyList.vue')
			},
			{
				path: '/frontapp/codyContentCreate',
				name: 'codyContentCreate',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/front/boardCody/frontCodyCreate.vue')
			},
			{
				path: '/frontapp/codyContentDetail',
				name: 'codyContentDetail',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/front/boardCody/frontCodyDetail.vue')
			},
			{
				path: '/frontapp/noticeList',
				name: 'frontNoticeListApp',
				meta: {
					isPublic: true
				},
				component: () => import('@/views/front/boardNotice/frontNoticeList.vue')
			},
			{
				path: '/frontapp/noticeDetail',
				name: 'frontNoticeDetailApp',
				meta: {
					isPublic: true
				},
				component: () =>
					import('@/views/front/boardNotice/frontNoticeDetail.vue')
			},
			{
				path: '/frontapp/error/:errorCode',
				name: 'frontErrorApp',
				component: () => import('@/views/error/FrontErrorPage.vue'),
				// component: () => import('@/views/error/AdminMessage.vue'),
				meta: {
					isPublic: true,
					hideAppBar: true
				}
			},
			{
				path: '/frontapp/*',
				name: 'frontNotFoundApp',
				redirect: '/frontapp/error/404'
			},
			{
				path: '/frontapp/*',
				name: 'front_system_error_app',
				redirect: '/frontapp/error/500'
			}
		]
	}
]
